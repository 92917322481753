const Migrations = [
	"01-convert-binary-short-name-setting",
	"02-store-settings-in-overlayplugin",
	"03-convert-light-theme",
	"04-convert-spell-layout",
	"06-change-critical-hp-setting-key",
	"07-convert-auto-hide-to-options",
];

export default Migrations;
