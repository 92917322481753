const SampleGameData = {
	type      : "CombatData",
	Encounter : {
		n                : "\n",
		t                : "\t",
		title            : "King Thordan",
		duration         : "03:58",
		DURATION         : "238",
		damage           : "1326301",
		"damage-m"       : "1.33",
		"damage-*"       : "1.33M",
		"DAMAGE-k"       : "1326",
		"DAMAGE-m"       : "1",
		"DAMAGE-b"       : "0",
		"DAMAGE-*"       : "1326K",
		dps              : "5572.69",
		"dps-*"          : "dps-*",
		DPS              : "5573",
		"DPS-k"          : "6",
		"DPS-m"          : "DPS-m",
		"DPS-*"          : "5572",
		encdps           : "5572.69",
		"encdps-*"       : "5.57K",
		ENCDPS           : "5573",
		"ENCDPS-k"       : "6",
		"ENCDPS-m"       : "0",
		"ENCDPS-*"       : "5572",
		hits             : "912",
		crithits         : "128",
		"crithit%"       : "0%",
		misses           : "0",
		hitfailed        : "7",
		swings           : "919",
		tohit            : "NaN",
		TOHIT            : "NaN",
		maxhit           : "YOU-Midare Setsugekka-14704",
		MAXHIT           : "YOU-14704",
		"maxhit-*"       : "YOU-Midare Setsugekka-14.70K",
		"MAXHIT-*"       : "YOU-14K",
		healed           : "561136",
		enchps           : "2357.71",
		"enchps-*"       : "2.36K",
		ENCHPS           : "2358",
		"ENCHPS-k"       : "2",
		"ENCHPS-m"       : "0",
		"ENCHPS-*"       : "2357",
		heals            : "330",
		critheals        : "128",
		"critheal%"      : "0%",
		cures            : "0",
		maxheal          : "Nicolaus Copernicus-Galvanize-7697",
		MAXHEAL          : "Nicolaus Copernicus-7697",
		maxhealward      : "Nicolaus Copernicus-Galvanize-7697",
		MAXHEALWARD      : "Nicolaus Copernicus-7697",
		"maxheal-*"      : "Nicolaus Copernicus-Galvanize-7.70K",
		"MAXHEAL-*"      : "Nicolaus Copernicus-7697",
		"maxhealward-*"  : "Nicolaus Copernicus-Galvanize-7.70K",
		"MAXHEALWARD-*"  : "Nicolaus Copernicus-7697",
		damagetaken      : "212198",
		"damagetaken-*"  : "212.20K",
		healstaken       : "561136",
		"healstaken-*"   : "561.14K",
		powerdrain       : "0",
		"powerdrain-*"   : "0",
		powerheal        : "30100",
		"powerheal-*"    : "30.10K",
		kills            : "0",
		deaths           : "0",
		CurrentZoneName  : "The Singularity Reactor",
		Last10DPS        : "",
		Last30DPS        : "",
		Last60DPS        : "",
		name             : "Encounter",
		Job              : "ENC",
		OverHealPct      : "0%",
		BlockPct         : "0%",
		DirectHitPct     : "0%",
		CritDirectHitPct : "0%",
		"damage%"        : "100%",
		"healed%"        : "100%",
	},
	Combatant : {
		"Hattori Hanzo" : {
			n                  : "\n",
			t                  : "\t",
			name               : "Hattori Hanzo",
			duration           : "03:58",
			DURATION           : "238",
			damage             : "245752",
			"damage-m"         : "0.25",
			"damage-b"         : "0.00",
			"damage-*"         : "245.75K",
			"DAMAGE-k"         : "246",
			"DAMAGE-m"         : "0",
			"DAMAGE-b"         : "0",
			"DAMAGE-*"         : "245K",
			"damage%"          : "18%",
			dps                : "1032.57",
			"dps-*"            : "1.03K",
			DPS                : "1033",
			"DPS-k"            : "1",
			"DPS-m"            : "0",
			"DPS-*"            : "1032",
			encdps             : "1032.57",
			"encdps-*"         : "1.03K",
			ENCDPS             : "1033",
			"ENCDPS-k"         : "1",
			"ENCDPS-m"         : "0",
			"ENCDPS-*"         : "1032",
			hits               : "120",
			crithits           : "14",
			"crithit%"         : "12%",
			crittypes          : "0.0%L - 0.0%F - 0.0%M",
			misses             : "0",
			hitfailed          : "0",
			swings             : "120",
			tohit              : "100.00",
			TOHIT              : "100",
			maxhit             : "Raiton-8908",
			MAXHIT             : "8908",
			"maxhit-*"         : "Raiton-8.91K",
			"MAXHIT-*"         : "8908",
			healed             : "0",
			"healed%"          : "0%",
			enchps             : "0.00",
			"enchps-*"         : "0",
			ENCHPS             : "0",
			"ENCHPS-k"         : "0",
			"ENCHPS-m"         : "0",
			"ENCHPS-*"         : "0",
			critheals          : "0",
			"critheal%"        : "0%",
			heals              : "0",
			cures              : "0",
			maxheal            : "",
			MAXHEAL            : "",
			maxhealward        : "",
			MAXHEALWARD        : "",
			"maxheal-*"        : "",
			"MAXHEAL-*"        : "",
			"maxhealward-*"    : "",
			"MAXHEALWARD-*"    : "",
			damagetaken        : "26141",
			"damagetaken-*"    : "26.14K",
			healstaken         : "54975",
			"healstaken-*"     : "54.98K",
			powerdrain         : "0",
			"powerdrain-*"     : "0",
			powerheal          : "0",
			"powerheal-*"      : "0",
			kills              : "0",
			deaths             : "0",
			threatstr          : "+(0)0/-(0)0",
			threatdelta        : "0",
			Last10DPS          : "0",
			Last30DPS          : "660",
			Last60DPS          : "371",
			Job                : "Nin",
			ParryPct           : "0%",
			BlockPct           : "0%",
			IncToHit           : "100.00",
			OverHealPct        : "0%",
			DirectHitPct       : "22%",
			DirectHitCount     : "26",
			CritDirectHitCount : "4",
			CritDirectHitPct   : "3%",
			overHeal           : "0",
			damageShield       : "0",
			absorbHeal         : "0",
			max_enc_dps        : 2653.87,
			enmity_percent     : "13.00",
		},
		YOU : {
			n                  : "\n",
			t                  : "\t",
			name               : "YOU",
			duration           : "03:56",
			DURATION           : "236",
			damage             : "235206",
			"damage-m"         : "0.24",
			"damage-b"         : "0.00",
			"damage-*"         : "235.21K",
			"DAMAGE-k"         : "235",
			"DAMAGE-m"         : "0",
			"DAMAGE-b"         : "0",
			"DAMAGE-*"         : "235K",
			"damage%"          : "17%",
			dps                : "996.64",
			"dps-*"            : "996",
			DPS                : "997",
			"DPS-k"            : "1",
			"DPS-m"            : "0",
			"DPS-*"            : "996",
			encdps             : "988.26",
			"encdps-*"         : "988",
			ENCDPS             : "988",
			"ENCDPS-k"         : "1",
			"ENCDPS-m"         : "0",
			"ENCDPS-*"         : "988",
			hits               : "105",
			crithits           : "23",
			"crithit%"         : "22%",
			crittypes          : "0.0%L - 0.0%F - 0.0%M",
			misses             : "0",
			hitfailed          : "0",
			swings             : "105",
			tohit              : "100.00",
			TOHIT              : "100",
			maxhit             : "Midare Setsugekka-14704",
			MAXHIT             : "14704",
			"maxhit-*"         : "Midare Setsugekka-14.70K",
			"MAXHIT-*"         : "14K",
			healed             : "0",
			"healed%"          : "0%",
			enchps             : "0.00",
			"enchps-*"         : "0",
			ENCHPS             : "0",
			"ENCHPS-k"         : "0",
			"ENCHPS-m"         : "0",
			"ENCHPS-*"         : "0",
			critheals          : "0",
			"critheal%"        : "0%",
			heals              : "0",
			cures              : "0",
			maxheal            : "",
			MAXHEAL            : "",
			maxhealward        : "",
			MAXHEALWARD        : "",
			"maxheal-*"        : "",
			"MAXHEAL-*"        : "",
			"maxhealward-*"    : "",
			"MAXHEALWARD-*"    : "",
			damagetaken        : "31935",
			"damagetaken-*"    : "31.94K",
			healstaken         : "62240",
			"healstaken-*"     : "62.24K",
			powerdrain         : "0",
			"powerdrain-*"     : "0",
			powerheal          : "0",
			"powerheal-*"      : "0",
			kills              : "0",
			deaths             : "0",
			threatstr          : "+(0)0/-(0)0",
			threatdelta        : "0",
			Last10DPS          : "0",
			Last30DPS          : "248",
			Last60DPS          : "124",
			Job                : "Sam",
			ParryPct           : "0%",
			BlockPct           : "0%",
			IncToHit           : "100.00",
			OverHealPct        : "0%",
			DirectHitPct       : "35%",
			DirectHitCount     : "37",
			CritDirectHitCount : "7",
			CritDirectHitPct   : "7%",
			overHeal           : "0",
			damageShield       : "0",
			absorbHeal         : "0",
			max_enc_dps        : 2463.91,
			enmity_percent     : "12.00",
		},
		"Lindsey Stirling" : {
			n                  : "\n",
			t                  : "\t",
			name               : "Lindsey Stirling",
			duration           : "04:00",
			DURATION           : "240",
			damage             : "207557",
			"damage-m"         : "0.21",
			"damage-b"         : "0.00",
			"damage-*"         : "207.56K",
			"DAMAGE-k"         : "208",
			"DAMAGE-m"         : "0",
			"DAMAGE-b"         : "0",
			"DAMAGE-*"         : "207K",
			"damage%"          : "15%",
			dps                : "864.82",
			"dps-*"            : "864",
			DPS                : "865",
			"DPS-k"            : "1",
			"DPS-m"            : "0",
			"DPS-*"            : "864",
			encdps             : "872.09",
			"encdps-*"         : "872",
			ENCDPS             : "872",
			"ENCDPS-k"         : "1",
			"ENCDPS-m"         : "0",
			"ENCDPS-*"         : "872",
			hits               : "197",
			crithits           : "20",
			"crithit%"         : "10%",
			crittypes          : "0.0%L - 0.0%F - 0.0%M",
			misses             : "0",
			hitfailed          : "0",
			swings             : "197",
			tohit              : "100.00",
			TOHIT              : "100",
			maxhit             : "Straight Shot-3124",
			MAXHIT             : "3124",
			"maxhit-*"         : "Straight Shot-3.12K",
			"MAXHIT-*"         : "3124",
			healed             : "0",
			"healed%"          : "0%",
			enchps             : "0.00",
			"enchps-*"         : "0",
			ENCHPS             : "0",
			"ENCHPS-k"         : "0",
			"ENCHPS-m"         : "0",
			"ENCHPS-*"         : "0",
			critheals          : "0",
			"critheal%"        : "0%",
			heals              : "0",
			cures              : "0",
			maxheal            : "",
			MAXHEAL            : "",
			maxhealward        : "",
			MAXHEALWARD        : "",
			"maxheal-*"        : "",
			"MAXHEAL-*"        : "",
			"maxhealward-*"    : "",
			"MAXHEALWARD-*"    : "",
			damagetaken        : "18697",
			"damagetaken-*"    : "18.70K",
			healstaken         : "56482",
			"healstaken-*"     : "56.48K",
			powerdrain         : "0",
			"powerdrain-*"     : "0",
			powerheal          : "0",
			"powerheal-*"      : "0",
			kills              : "0",
			deaths             : "0",
			threatstr          : "+(0)0/-(0)0",
			threatdelta        : "0",
			Last10DPS          : "0",
			Last30DPS          : "325",
			Last60DPS          : "287",
			Job                : "Brd",
			ParryPct           : "0%",
			BlockPct           : "0%",
			IncToHit           : "100.00",
			OverHealPct        : "0%",
			DirectHitPct       : "26%",
			DirectHitCount     : "51",
			CritDirectHitCount : "7",
			CritDirectHitPct   : "4%",
			overHeal           : "0",
			damageShield       : "0",
			absorbHeal         : "0",
			max_enc_dps        : 1769.63,
			enmity_percent     : "9.00",
		},
		Beedle : {
			n                  : "\n",
			t                  : "\t",
			name               : "Beedle",
			duration           : "04:02",
			DURATION           : "242",
			damage             : "188946",
			"damage-m"         : "0.19",
			"damage-b"         : "0.00",
			"damage-*"         : "188.95K",
			"DAMAGE-k"         : "189",
			"DAMAGE-m"         : "0",
			"DAMAGE-b"         : "0",
			"DAMAGE-*"         : "188K",
			"damage%"          : "14%",
			dps                : "780.77",
			"dps-*"            : "780",
			DPS                : "781",
			"DPS-k"            : "1",
			"DPS-m"            : "0",
			"DPS-*"            : "780",
			encdps             : "793.89",
			"encdps-*"         : "793",
			ENCDPS             : "794",
			"ENCDPS-k"         : "1",
			"ENCDPS-m"         : "0",
			"ENCDPS-*"         : "793",
			hits               : "192",
			crithits           : "35",
			"crithit%"         : "18%",
			crittypes          : "0.0%L - 0.0%F - 0.0%M",
			misses             : "0",
			hitfailed          : "0",
			swings             : "192",
			tohit              : "100.00",
			TOHIT              : "100",
			maxhit             : "Empyreal Arrow-3902",
			MAXHIT             : "3902",
			"maxhit-*"         : "Empyreal Arrow-3.90K",
			"MAXHIT-*"         : "3902",
			healed             : "0",
			"healed%"          : "0%",
			enchps             : "0.00",
			"enchps-*"         : "0",
			ENCHPS             : "0",
			"ENCHPS-k"         : "0",
			"ENCHPS-m"         : "0",
			"ENCHPS-*"         : "0",
			critheals          : "0",
			"critheal%"        : "0%",
			heals              : "0",
			cures              : "0",
			maxheal            : "",
			MAXHEAL            : "",
			maxhealward        : "",
			MAXHEALWARD        : "",
			"maxheal-*"        : "",
			"MAXHEAL-*"        : "",
			"maxhealward-*"    : "",
			"MAXHEALWARD-*"    : "",
			damagetaken        : "28198",
			"damagetaken-*"    : "28.20K",
			healstaken         : "61864",
			"healstaken-*"     : "61.86K",
			powerdrain         : "0",
			"powerdrain-*"     : "0",
			powerheal          : "0",
			"powerheal-*"      : "0",
			kills              : "0",
			deaths             : "0",
			threatstr          : "+(0)0/-(0)0",
			threatdelta        : "0",
			Last10DPS          : "0",
			Last30DPS          : "237",
			Last60DPS          : "134",
			Job                : "Brd",
			ParryPct           : "100%",
			BlockPct           : "0%",
			IncToHit           : "100.00",
			OverHealPct        : "0%",
			DirectHitPct       : "15%",
			DirectHitCount     : "28",
			CritDirectHitCount : "4",
			CritDirectHitPct   : "2%",
			overHeal           : "0",
			damageShield       : "0",
			absorbHeal         : "0",
			max_enc_dps        : 1847.35,
			enmity_percent     : "9.00",
		},
		Uther : {
			n                  : "\n",
			t                  : "\t",
			name               : "Uther",
			duration           : "03:58",
			DURATION           : "238",
			damage             : "177958",
			"damage-m"         : "0.18",
			"damage-b"         : "0.00",
			"damage-*"         : "177.96K",
			"DAMAGE-k"         : "178",
			"DAMAGE-m"         : "0",
			"DAMAGE-b"         : "0",
			"DAMAGE-*"         : "177K",
			"damage%"          : "13%",
			dps                : "747.72",
			"dps-*"            : "747",
			DPS                : "748",
			"DPS-k"            : "1",
			"DPS-m"            : "0",
			"DPS-*"            : "747",
			encdps             : "747.72",
			"encdps-*"         : "747",
			ENCDPS             : "748",
			"ENCDPS-k"         : "1",
			"ENCDPS-m"         : "0",
			"ENCDPS-*"         : "747",
			hits               : "143",
			crithits           : "20",
			"crithit%"         : "14%",
			crittypes          : "0.0%L - 0.0%F - 0.0%M",
			misses             : "0",
			hitfailed          : "0",
			swings             : "143",
			tohit              : "100.00",
			TOHIT              : "100",
			maxhit             : "Royal Authority-6755",
			MAXHIT             : "6755",
			"maxhit-*"         : "Royal Authority-6.76K",
			"MAXHIT-*"         : "6755",
			healed             : "0",
			"healed%"          : "0%",
			enchps             : "0.00",
			"enchps-*"         : "0",
			ENCHPS             : "0",
			"ENCHPS-k"         : "0",
			"ENCHPS-m"         : "0",
			"ENCHPS-*"         : "0",
			critheals          : "0",
			"critheal%"        : "0%",
			heals              : "0",
			cures              : "0",
			maxheal            : "",
			MAXHEAL            : "",
			maxhealward        : "",
			MAXHEALWARD        : "",
			"maxheal-*"        : "",
			"MAXHEAL-*"        : "",
			"maxhealward-*"    : "",
			"MAXHEALWARD-*"    : "",
			damagetaken        : "19498",
			"damagetaken-*"    : "19.50K",
			healstaken         : "78112",
			"healstaken-*"     : "78.11K",
			powerdrain         : "0",
			"powerdrain-*"     : "0",
			powerheal          : "15500",
			"powerheal-*"      : "15.50K",
			kills              : "0",
			deaths             : "0",
			threatstr          : "+(0)0/-(0)0",
			threatdelta        : "0",
			Last10DPS          : "0",
			Last30DPS          : "367",
			Last60DPS          : "221",
			Job                : "Pld",
			ParryPct           : "3%",
			BlockPct           : "41%",
			IncToHit           : "100.00",
			OverHealPct        : "0%",
			DirectHitPct       : "25%",
			DirectHitCount     : "35",
			CritDirectHitCount : "2",
			CritDirectHitPct   : "1%",
			overHeal           : "0",
			damageShield       : "0",
			absorbHeal         : "0",
			max_enc_dps        : 1917.07,
			enmity_percent     : "100.00",
		},
		Ashor : {
			n                  : "\n",
			t                  : "\t",
			name               : "Ashor",
			duration           : "03:54",
			DURATION           : "234",
			damage             : "174040",
			"damage-m"         : "0.17",
			"damage-b"         : "0.00",
			"damage-*"         : "174.04K",
			"DAMAGE-k"         : "174",
			"DAMAGE-m"         : "0",
			"DAMAGE-b"         : "0",
			"DAMAGE-*"         : "174K",
			"damage%"          : "13%",
			dps                : "743.76",
			"dps-*"            : "743",
			DPS                : "744",
			"DPS-k"            : "1",
			"DPS-m"            : "0",
			"DPS-*"            : "743",
			encdps             : "731.26",
			"encdps-*"         : "731",
			ENCDPS             : "731",
			"ENCDPS-k"         : "1",
			"ENCDPS-m"         : "0",
			"ENCDPS-*"         : "731",
			hits               : "100",
			crithits           : "12",
			"crithit%"         : "12%",
			crittypes          : "0.0%L - 0.0%F - 0.0%M",
			misses             : "0",
			hitfailed          : "0",
			swings             : "100",
			tohit              : "100.00",
			TOHIT              : "100",
			maxhit             : "Souleater-5231",
			MAXHIT             : "5231",
			"maxhit-*"         : "Souleater-5.23K",
			"MAXHIT-*"         : "5231",
			healed             : "33148",
			"healed%"          : "5%",
			enchps             : "139.28",
			"enchps-*"         : "139",
			ENCHPS             : "139",
			"ENCHPS-k"         : "0",
			"ENCHPS-m"         : "0",
			"ENCHPS-*"         : "139",
			critheals          : "4",
			"critheal%"        : "31%",
			heals              : "13",
			cures              : "0",
			maxheal            : "Souleater-3503",
			MAXHEAL            : "3503",
			maxhealward        : "Souleater-3503",
			MAXHEALWARD        : "3503",
			"maxheal-*"        : "Souleater-3.50K",
			"MAXHEAL-*"        : "3.50K",
			"maxhealward-*"    : "Souleater-3.50K",
			"MAXHEALWARD-*"    : "3.50K",
			damagetaken        : "31348",
			"damagetaken-*"    : "31.35K",
			healstaken         : "84451",
			"healstaken-*"     : "84.45K",
			powerdrain         : "0",
			"powerdrain-*"     : "0",
			powerheal          : "12600",
			"powerheal-*"      : "12.60K",
			kills              : "0",
			deaths             : "0",
			threatstr          : "+(0)0/-(0)0",
			threatdelta        : "0",
			Last10DPS          : "0",
			Last30DPS          : "555",
			Last60DPS          : "336",
			Job                : "Drk",
			ParryPct           : "100%",
			BlockPct           : "0%",
			IncToHit           : "100.00",
			OverHealPct        : "0%",
			DirectHitPct       : "27%",
			DirectHitCount     : "27",
			CritDirectHitCount : "3",
			CritDirectHitPct   : "3%",
			overHeal           : "0",
			damageShield       : "0",
			absorbHeal         : "0",
			max_enc_dps        : 1711.77,
			enmity_percent     : "20.00",
		},
		"Sigmund Freud" : {
			n                  : "\n",
			t                  : "\t",
			name               : "Sigmund Freud",
			duration           : "04:03",
			DURATION           : "243",
			damage             : "96842",
			"damage-m"         : "0.10",
			"damage-b"         : "0.00",
			"damage-*"         : "96.84K",
			"DAMAGE-k"         : "97",
			"DAMAGE-m"         : "0",
			"DAMAGE-b"         : "0",
			"DAMAGE-*"         : "96K",
			"damage%"          : "7%",
			dps                : "398.53",
			"dps-*"            : "398",
			DPS                : "399",
			"DPS-k"            : "0",
			"DPS-m"            : "0",
			"DPS-*"            : "398",
			encdps             : "406.90",
			"encdps-*"         : "406",
			ENCDPS             : "407",
			"ENCDPS-k"         : "0",
			"ENCDPS-m"         : "0",
			"ENCDPS-*"         : "406",
			hits               : "55",
			crithits           : "4",
			"crithit%"         : "7%",
			crittypes          : "0.0%L - 0.0%F - 0.0%M",
			misses             : "0",
			hitfailed          : "7",
			swings             : "62",
			tohit              : "88.71",
			TOHIT              : "89",
			maxhit             : "Broil-5278",
			MAXHIT             : "5278",
			"maxhit-*"         : "Broil-5.28K",
			"MAXHIT-*"         : "5278",
			healed             : "196901",
			"healed%"          : "35%",
			enchps             : "827.32",
			"enchps-*"         : "827",
			ENCHPS             : "827",
			"ENCHPS-k"         : "1",
			"ENCHPS-m"         : "0",
			"ENCHPS-*"         : "827",
			critheals          : "17",
			"critheal%"        : "13%",
			heals              : "131",
			cures              : "0",
			maxheal            : "Galvanize-5463",
			MAXHEAL            : "5463",
			maxhealward        : "Galvanize-5463",
			MAXHEALWARD        : "5463",
			"maxheal-*"        : "Galvanize-5.46K",
			"MAXHEAL-*"        : "5.46K",
			"maxhealward-*"    : "Galvanize-5.46K",
			"MAXHEALWARD-*"    : "5.46K",
			damagetaken        : "17438",
			"damagetaken-*"    : "17.44K",
			healstaken         : "75568",
			"healstaken-*"     : "75.57K",
			powerdrain         : "0",
			"powerdrain-*"     : "0",
			powerheal          : "1000",
			"powerheal-*"      : "1.00K",
			kills              : "0",
			deaths             : "0",
			threatstr          : "+(0)0/-(0)0",
			threatdelta        : "0",
			Last10DPS          : "0",
			Last30DPS          : "167",
			Last60DPS          : "84",
			Job                : "Sch",
			ParryPct           : "0%",
			BlockPct           : "0%",
			IncToHit           : "77.14",
			OverHealPct        : "66%",
			DirectHitPct       : "0%",
			DirectHitCount     : "0",
			CritDirectHitCount : "0",
			CritDirectHitPct   : "0%",
			overHeal           : "126426",
			damageShield       : "5463",
			absorbHeal         : "0",
			max_enc_dps        : 1258.43,
			enmity_percent     : "9.00",
		},
		"Nicolaus Copernicus" : {
			n                  : "\n",
			t                  : "\t",
			name               : "Nicolaus Copernicus",
			duration           : "03:56",
			DURATION           : "236",
			damage             : "0",
			"damage-m"         : "0.00",
			"damage-b"         : "0.00",
			"damage-*"         : "0",
			"DAMAGE-k"         : "0",
			"DAMAGE-m"         : "0",
			"DAMAGE-b"         : "0",
			"DAMAGE-*"         : "0",
			"damage%"          : "0%",
			dps                : "0.00",
			"dps-*"            : "0",
			DPS                : "0",
			"DPS-k"            : "0",
			"DPS-m"            : "0",
			"DPS-*"            : "0",
			encdps             : "0.00",
			"encdps-*"         : "0",
			ENCDPS             : "0",
			"ENCDPS-k"         : "0",
			"ENCDPS-m"         : "0",
			"ENCDPS-*"         : "0",
			hits               : "0",
			crithits           : "0",
			"crithit%"         : "0%",
			crittypes          : "0.0%L - 0.0%F - 0.0%M",
			misses             : "0",
			hitfailed          : "0",
			swings             : "0",
			tohit              : "---",
			TOHIT              : "---",
			maxhit             : "",
			MAXHIT             : "",
			"maxhit-*"         : "",
			"MAXHIT-*"         : "",
			healed             : "331087",
			"healed%"          : "59%",
			enchps             : "1391.12",
			"enchps-*"         : "1.39K",
			ENCHPS             : "1391",
			"ENCHPS-k"         : "1",
			"ENCHPS-m"         : "0",
			"ENCHPS-*"         : "1391",
			critheals          : "37",
			"critheal%"        : "20%",
			heals              : "186",
			cures              : "0",
			maxheal            : "Galvanize-7697",
			MAXHEAL            : "7697",
			maxhealward        : "Galvanize-7697",
			MAXHEALWARD        : "7697",
			"maxheal-*"        : "Galvanize-7.70K",
			"MAXHEAL-*"        : "7.70K",
			"maxhealward-*"    : "Galvanize-7.70K",
			"MAXHEALWARD-*"    : "7.70K",
			damagetaken        : "38943",
			"damagetaken-*"    : "38.94K",
			healstaken         : "87444",
			"healstaken-*"     : "87.44K",
			powerdrain         : "0",
			"powerdrain-*"     : "0",
			powerheal          : "1000",
			"powerheal-*"      : "1.00K",
			kills              : "0",
			deaths             : "0",
			threatstr          : "+(0)0/-(0)0",
			threatdelta        : "0",
			Last10DPS          : "",
			Last30DPS          : "",
			Last60DPS          : "",
			Job                : "Sch",
			ParryPct           : "100%",
			BlockPct           : "0%",
			IncToHit           : "97.22",
			OverHealPct        : "58%",
			DirectHitPct       : "0%",
			DirectHitCount     : "0",
			CritDirectHitCount : "0",
			CritDirectHitPct   : "0%",
			overHeal           : "145068",
			damageShield       : "81876",
			absorbHeal         : "0",
			max_enc_dps        : 0,
			enmity_percent     : "5.00",
		},
	},
	isActive : "false",
};

export default SampleGameData;
