const SampleAggroData = {
	type      : "EnmityAggroList",
	AggroList : [
		{
			ID              : 1074087459,
			Name            : "Wood Eyes",
			HateRate        : 100,
			Order           : 0,
			isCurrentTarget : true,
			CurrentHP       : 75316,
			MaxHP           : 100760,
			Target          : {
				ID       : -1,
				OwnerID  : 0,
				Name     : "Uther",
				Enmity   : 0,
				isMe     : false,
				HateRate : 0,
				Job      : 0,
			},
			Effects : [],
		},
		{
			ID              : 1074087457,
			Name            : "Wood Eyes",
			HateRate        : 100,
			Order           : 0,
			isCurrentTarget : false,
			CurrentHP       : 56960,
			MaxHP           : 100760,
			Target          : {
				ID       : -1,
				OwnerID  : 0,
				Name     : "Uther",
				Enmity   : 0,
				isMe     : false,
				HateRate : 0,
				Job      : 0,
			},
			Effects : [],
		},
		{
			ID              : 1074087462,
			Name            : "Island Rail",
			HateRate        : 100,
			Order           : 0,
			isCurrentTarget : false,
			CurrentHP       : 80365,
			MaxHP           : 100760,
			Target          : {
				ID       : -2,
				OwnerID  : 0,
				Name     : "Ashor",
				Enmity   : 0,
				isMe     : false,
				HateRate : 0,
				Job      : 0,
			},
			Effects : [],
		},
	],
};

export default SampleAggroData;
